import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './img/un.png';

// Função debounce para atrasar a execução da pesquisa enquanto o usuário digita
function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
}

function Header() {
  const [menus, setMenus] = useState([]);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchRef = useRef(null); // Referência para o componente de pesquisa
   
  

  useEffect(() => {
    // Buscar menus
    const fetchMenus = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/menus');
        if (!response.ok) {
          throw new Error('Erro na resposta da API');
        }
        const data = await response.json();
        console.log('Dados recebidos da API:', data); // Log para ver a estrutura dos dados
        if (Array.isArray(data)) {
          setMenus(data);
        } else {
          console.warn('A resposta da API não é um array:', data);
        }
      } catch (error) {
        console.error('Erro ao carregar menus:', error);
      }
    };

    fetchMenus();

  }, []);
  
  // Função para buscar os mangás
  const fetchMangas = async (searchQuery) => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://api.arthurcomics.com/api/search-mangas?query=${searchQuery}`);
      setResults(response.data);
    } catch (err) {
      setError('Erro ao buscar os mangás.');
    } finally {
      setLoading(false);
    }
  };

  // Pesquisa automática ao digitar (usando debounce)
  const handleSearch = debounce((searchQuery) => {
    if (searchQuery.trim()) {
      fetchMangas(searchQuery);
    } else {
      setResults([]); // Limpar resultados se a consulta estiver vazia
    }
  }, 500); // Atraso de 500ms

  // Atualizar o estado e disparar a pesquisa automaticamente
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    handleSearch(e.target.value);
  };

  // Função para fechar os resultados ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setResults([]); // Limpar resultados se clicar fora
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);
  return (
    <header id="main_header" className="header grid sm:-mb-4 -mb-[4vw] sm:relative transition-all duration-300 sticky top-0 left-0 z-[161] border-white/5">
      <div className='grid sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] fulzinho max-w-6xl mx-auto sm:p-4 p-[4vw]'>
        <div className='flex justify-between items-center'>
          <div className='flex sm:gap-4 gap-[4vw] items-center'>
             <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
          </div>
          <div className='flex sm:gap-4 gap-[4vw]'>
          <div ref={searchRef} className='flex  sm:gap-4 gap-[4vw]' id="showPanelButton">
      <form className='sm:px-5 px-[4vw] flex sm:w-fit w-full justify-center items-center h-11 gap-3 bg-white/10 transition-all rounded-full'>
      
        <input
          className='placeholder:font-light placeholder:text-white/50 text-base w-full bg-transparent focus-within:outline-none'
          type="text"
          placeholder="Digite o nome do mangá"
          value={query}
          onChange={handleInputChange} // Chama a função de pesquisa automática
          required
        /><img class="w-6 ml-0.5" src="https://api.iconify.design/ph:magnifying-glass-bold.svg?color=white" />
      </form>

      {loading && <p className='loading-search'>Carregando...</p>}
      {error && <p>{error}</p>}

      <ul className='ul-search'>
        {results.map((manga) => (
          <Link to={`/manga/${encodeURIComponent(manga._id)}`} className='a-search' key={manga._id}>
            <li className='li-search'>
              <div className='cover-search'>
                <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='manga-cover-search' />
              </div>
              <div className='info-search'>
                <h3 className='title-search'>{manga.title}</h3>
                <p className='sinopse-search'>{manga.synopsis}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
        <div id="bottomHeader" className="flex z-[49]  justify-center sm:bg-transparent border-white/5 bg-[--theme_color] sm:gap-4 gap-[4vw] sm:relative sm:w-auto fixed w-full bottom-0 left-0 sm:p-0 p-[4vw]">
          {menus.length > 0 ? (
            menus.map(menu => (

                <Link key={menu._id} to={menu.url} className="nav-link flex  sm:w-fit w-1/2 justify-center items-center h-11 sm:px-5 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
                  <div class="sm:block hidden font-medium">{menu.label}</div>
                </Link>
            ))
          ) : (
            <li><span>Não há menus disponíveis.</span></li>
          )}
        </div>
      </div>
      </div>
      </div>
    </header>
  );
}

export default Header;
