import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './GenresPage.css';

const GenresPage = () => {
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [mangas, setMangas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  // Carregar gêneros
  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const response = await axios.get('https://api.arthurcomics.com/api/genres');
        setGenres(response.data);
      } catch (err) {
        setError('Erro ao carregar gêneros.');
      } finally {
        setLoading(false);
      }
    };

    fetchGenres();
  }, []);

  // Carregar mangás do gênero selecionado ou todos os mangás
  useEffect(() => {
    const genreFromQuery = new URLSearchParams(location.search).get('genre');
    if (genreFromQuery) {
      setSelectedGenre(genreFromQuery);
    }

    const fetchMangas = async () => {
      setLoading(true);
      try {
        const url = genreFromQuery 
          ? `https://api.arthurcomics.com/api/mangas/genre/${encodeURIComponent(genreFromQuery)}`
          : 'https://api.arthurcomics.com/api/mangas'; // URL para todos os mangás
        const response = await axios.get(url);
        setMangas(response.data);
      } catch (err) {
        setError('Erro ao carregar mangás.');
      } finally {
        setLoading(false);
      }
    };

    fetchMangas();
  }, [location.search]);

  const handleGenreClick = (genre) => {
    setSelectedGenre(genre);
    navigate(`?genre=${encodeURIComponent(genre)}`);
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className='grid sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto sm:p-4 p-[4vw] relative'>
      <div className="grid sm:gap-10 gap-[4vw]">
        <div className="flex sm:flex-row sm:gap-4 gap-[2vw] flex-col justify-between sm:items-center">
          <h2 className="sm:text-2xl text-[5.5vw] font-bold">Gêneros</h2>
        </div>
        <div id="series_tags_page" className='flex flex-wrap gap-x-2.5 gap-y-3'>
          <button onClick={() => handleGenreClick('')} className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>Todos</button>
          {genres.map((genre, index) => (
            <button key={index} onClick={() => handleGenreClick(genre)} className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{genre}</button>
          ))}
        </div>

        {selectedGenre && (
          <div>
            <h2>Mangás de {selectedGenre}</h2>
          </div>
        )}
        <div id="searched_series_page" className='grid 2xl:grid-cols-7 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 sm:gap-x-4 sm:gap-y-8 gap-4'>
          {mangas.map((manga) => {
            const coverImageUrl = `https://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;

            return (
              <button key={manga._id} className='group text-left relative h-fit w-full overflow-hidden grid transition-all sm:hover:bg-white/10 sm:hover:ring-white/10 sm:hover:ring-[0.5rem] sm:hover:scale-95 sm:hover:rounded-sm flex'>
                <Link to={`/manga/${manga.title}`} className='grid border aspect-[0.75/1] border-white/5 rounded-lg overflow-hidden'>
                  <div className='bg-white/10 bg-center bg-cover w-full h-full'
                    alt={manga.title}
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }}
                  ></div>
                </Link>
                <div className='flex sm:gap-2.5 gap-[2vw] justify-start items-start sm:-mt-0.5 -mt-[0.5vw] w-full'>
                  <Link to={`/manga/${manga.title}`} className='grid w-full'>
                    <h3 className='h-fit sm:text-base text-[3.5vw] leading-[3vw] truncate sm:leading-4 break-words'>{manga.title}</h3>
                  </Link>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GenresPage;
