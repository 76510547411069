import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ReadingPage.css';

function ReadingPage() {
  const { book, chapter } = useParams(); 
  const [manga, setManga] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [chapterImages, setChapterImages] = useState([]);
  const [bookChapters, setBookChapters] = useState([]);
  const [loadingError, setLoadingError] = useState(null);
  const [showChapters, setShowChapters] = useState(false); // Estado para controlar a exibição da lista de capítulos

  useEffect(() => {
    fetch(`https://api.arthurcomics.com/api/mangas/${book}`)
      .then(response => response.json())
      .then(data => {
        setManga(data); 
      })
      .catch(err => {
        console.error('Erro ao buscar dados do mangá:', err);
        setLoadingError(`Erro ao buscar dados do mangá: ${err.message}`);
      });

    fetch('https://api.arthurcomics.com/api/chapters')
      .then((response) => response.json())
      .then((data) => {
        const currentBookChapters = data.filter((c) => c.manga.toLowerCase() === book.toLowerCase());
        setBookChapters(currentBookChapters);

        const currentChapter = currentBookChapters.find((c) => c.chapterNumber === chapter);

        if (currentChapter) {
          const imageUrls = currentChapter.content.split(',')
            .map(imagePath => `https://api.arthurcomics.com/${imagePath}`)
            .sort((a, b) => {
              const numA = parseInt(a.match(/\/(\d+)\./)?.[1], 10);
              const numB = parseInt(b.match(/\/(\d+)\./)?.[1], 10);
              return numA - numB;
            });

          setChapterImages(imageUrls);
        } else {
          setLoadingError('Capítulo não encontrado.');
        }

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.error('Erro ao buscar capítulos:', err);
        setLoadingError(`Erro ao buscar capítulos: ${err.message}`);
      });

      const fetchMangaAndChapterData = async () => {
        try {
          const response = await fetch(`https://api.arthurcomics.com/api/mangas/${book}`);
          const mangaData = await response.json();
          setManga(mangaData);
    
          const chapterResponse = await fetch(`https://api.arthurcomics.com/api/chapters`);
          const chaptersData = await chapterResponse.json();
          const currentBookChapters = chaptersData.filter((c) => c.manga.toLowerCase() === book.toLowerCase());
          setBookChapters(currentBookChapters);
    
          const currentChapter = currentBookChapters.find((c) => c.chapterNumber === chapter);
          if (currentChapter) {
            const imageUrls = currentChapter.content.split(',').map(imagePath => `https://api.arthurcomics.com/${imagePath}`);
            setChapterImages(imageUrls);
          } else {
            setLoadingError('Capítulo não encontrado.');
          }
        } catch (err) {
          setLoadingError(`Erro ao buscar dados: ${err.message}`);
        }
      };
      fetchMangaAndChapterData();
  }, [book, chapter]);

  const getNextChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const nextChapter = bookChapters[currentChapterIndex + 1];
    return nextChapter ? `/manga/${book}/capitulo/${nextChapter.chapterNumber}` : null;
  };

  const getPreviousChapterLink = () => {
    const currentChapterIndex = bookChapters.findIndex((c) => c.chapterNumber === chapter);
    const previousChapter = bookChapters[currentChapterIndex - 1];
    return previousChapter ? `/manga/${book}/capitulo/${previousChapter.chapterNumber}` : '#';
  };

  const getBookPageLink = () => {
    return `/manga/${book}`;
  };

  const nextChapterLink = getNextChapterLink();
  return (
    <div>
      <div className="reading-page">
        <header id="chapter_header" className="grid sm:mt-4 transition-all sm:border-y border-t sm:sticky top-[--topSet] left-0 z-[49] border-white/5">
            <div className="flex text-center justify-center items-center w-full 2xl:max-w-[100rem] max-w-6xl mx-auto sm:p-4 p-[4vw]">
                <h1>
                  <span className="flex flex-wrap sm:gap-1.5 gap-[1.5vw] sm:justify-center items-center">
                    <span>
                      {manga ? (
                        <Link to={`/manga/${manga._id}`} alt={manga.title} title={manga.title} className="opacity-100 hover:opacity-50 transition-all">{manga.title}</Link>
                      ) : (
                        <p>Carregando título...</p>
                      )}
                      <h1>- Capítulo {chapter}</h1>
                    </span>
                  </span>
                </h1>
            </div>
        </header>

        {/* Lista de Capítulos */}
        {showChapters && (
          <div id="chapters_panel" className='fixed transition-all duration-500 left-0 flex justify-center items-end w-full z-10 panel_shown'>
            <div id='chapters_panel_scroll' className='bg-[--theme_color] border border-white/10 mx-auto rounded-t-3xl w-full h-auto max-h-[50%] max-w-3xl sm:p-4 sm:pb-4 p-[4vw] pb-0 grid sm:gap-10 gap-[4vw] simplebar-scrollable-y'>
          <div className='simplebar-wrapper'>
            <div className='simplebar-mask'>
            <div className='simplebar-offset'>
            <div className='simplebar-content-wrapper'>
            <div className='simplebar-content'>
              <div className='flex items-center justify-between sm:mb-4 mb-[4vw]'><h2 className='sm:text-2xl text-[5.5vw] font-bold'>Capítulos</h2></div>
          <div id="chapters" className="chapter-list grid grid-cols-1 sm:gap-4 gap-[3vw] sm:pb-4 pb-[4vw]">
           
            <ul className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-[3vw] sm:pb-4 pb-[4vw]">
              {bookChapters.map((chap) => (
                <li key={chap.chapterNumber} >
                 <Link to={`/manga/${book}/capitulo/${chap.chapterNumber}`} className='group cursor-pointer relative overflow-hidden flex  items-center justify-between sm:gap-4 gap-[4vw] hover:bg-white/20 bg-white/10 rounded-2xl sm:p-2 p-[2vw] transition-all'>
        <div className='flex items-center sm:gap-4 gap-[4vw]'>
          <div className="border inline-table border-white/10 bg-white/10 overflow-hidden rounded-xl">
          {chap.thumbnail && (
              <div
                style={{
                  backgroundImage: `url(https://api.arthurcomics.com/${chap.thumbnail.replace(/\\/g, '/')})`,
                }}
                className="chapter-thumbnail-image aspect-[2/1.5] w-28 bg-white/10 bg-cover bg-center rounded-lg"
              ></div>
            )}

          </div>
          <div className="grid h-fit">
            <span className="flex gap-1 justify-start items-center overflow-hidden">
              <span className="text-sm truncate">Capítulo {chap.chapterNumber}</span>
            </span>
          </div>
        </div>
                </Link></li>
              ))}
            </ul>
          </div></div></div></div></div></div></div></div>
        )}

        <div className="navigation-buttons-top flex gap-4 justify-center items-center">
          <Link to={getPreviousChapterLink()} className="prev-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
            <img className="w-6" src="https://api.iconify.design/mdi:arrow-left.svg?color=white" />
          </Link>
          <Link to={getBookPageLink()} className="back-to-book sm:px-5 px-[4vw] flex sm:w-fit w-full justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
            <img className="w-6" src="https://api.iconify.design/ph:squares-four-fill.svg?color=white" /> Info
          </Link>
          {/* Botão para mostrar/ocultar a lista de capítulos */}
        <button onClick={() => setShowChapters(!showChapters)} className="toggle-chapters-button">
        <button onClick={() => setShowChapters(!showChapters)} className="toggle-chapters-button">
  {showChapters ? 'Ocultar Capítulos' : (
    <span className="sm:px-5 px-[4vw] flex sm:w-fit w-full justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
      <img className="w-6" src="https://api.iconify.design/ph:squares-four-fill.svg?color=white" alt="Ícone de Capítulos" />
      Capítulos
    </span>
  )}
</button>
        </button>
          {nextChapterLink && (
            <Link to={nextChapterLink} className="next-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
              <img className="w-6" src="https://api.iconify.design/mdi:arrow-right.svg?color=white" />
            </Link>
          )}
        </div>

        <div className="reader-area">
          {chapterImages.length > 0 ? (
            chapterImages.map((image, index) => (
              <img key={index} src={image} alt={`Capítulo ${chapter} - Imagem ${index + 1}`} />
            ))
          ) : (
            <p>Carregando imagens...</p>
          )}
        </div>

        <div className="navigation-buttons flex gap-4 justify-center items-center">
          <Link to={getPreviousChapterLink()} className="prev-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
            <img className="w-6" src="https://api.iconify.design/mdi:arrow-left.svg?color=white" />
          </Link>
          <Link to={getBookPageLink()} className="back-to-book sm:px-5 px-[4vw] flex sm:w-fit w-full justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
            <img className="w-6" src="https://api.iconify.design/ph:squares-four-fill.svg?color=white" /> Info
          </Link>
          {nextChapterLink && (
            <Link to={nextChapterLink} className="next-chapter flex sm:w-full w-1/2 sm:max-w-[10rem] justify-center items-center h-11 gap-3 bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-full">
              <img className="w-6" src="https://api.iconify.design/mdi:arrow-right.svg?color=white" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReadingPage;
