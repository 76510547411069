// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Importando estilos do slick-carousel */
/* Slide.css */

.slick-slide img {
    width: 100%; /* Ajuste conforme necessário */
  }
  
  .slick-dots li button:before {
    color: #000; /* Cor das bolinhas */
  }
  
  .slick-prev, .slick-next {
    width: 30px;
    height: 30px;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  a.flex.justify-center.overflow-hidden.relative.items-center.w-full.splide__slide.sm\\:rounded-lg.group {
    background: #fff0;
}`, "",{"version":3,"sources":["webpack://./src/Slide.css"],"names":[],"mappings":"AAAA,yCAAyC;AAGzC,cAAc;;AAEd;IACI,WAAW,EAAE,+BAA+B;EAC9C;;EAEA;IACE,WAAW,EAAE,qBAAqB;EACpC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;EACA;IACE,iBAAiB;AACrB","sourcesContent":["/* Importando estilos do slick-carousel */\n@import \"~slick-carousel/slick/slick.css\";\n@import \"~slick-carousel/slick/slick-theme.css\";\n/* Slide.css */\n\n.slick-slide img {\n    width: 100%; /* Ajuste conforme necessário */\n  }\n  \n  .slick-dots li button:before {\n    color: #000; /* Cor das bolinhas */\n  }\n  \n  .slick-prev, .slick-next {\n    width: 30px;\n    height: 30px;\n    background: rgba(0,0,0,0.5);\n    border-radius: 50%;\n  }\n  \n  .slick-prev {\n    left: 10px;\n  }\n  \n  .slick-next {\n    right: 10px;\n  }\n  a.flex.justify-center.overflow-hidden.relative.items-center.w-full.splide__slide.sm\\:rounded-lg.group {\n    background: #fff0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
