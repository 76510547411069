import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './MangaSearch.css';

// Função debounce para atrasar a execução da pesquisa enquanto o usuário digita
function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
}

function MangaSearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchRef = useRef(null); // Referência para o componente de pesquisa

  // Função para buscar os mangás
  const fetchMangas = async (searchQuery) => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://api.arthurcomics.com/api/search-mangas?query=${searchQuery}`);
      setResults(response.data);
    } catch (err) {
      setError('Erro ao buscar os mangás.');
    } finally {
      setLoading(false);
    }
  };

  // Pesquisa automática ao digitar (usando debounce)
  const handleSearch = debounce((searchQuery) => {
    if (searchQuery.trim()) {
      fetchMangas(searchQuery);
    } else {
      setResults([]); // Limpar resultados se a consulta estiver vazia
    }
  }, 500); // Atraso de 500ms

  // Atualizar o estado e disparar a pesquisa automaticamente
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    handleSearch(e.target.value);
  };

  // Função para fechar os resultados ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setResults([]); // Limpar resultados se clicar fora
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef]);

  return (
    <div ref={searchRef}>
      <form className='sm:px-5 px-[4vw] flex sm:w-fit w-full justify-center items-center h-11 gap-3 bg-white/10 transition-all rounded-full'>
        <input
          className='placeholder:font-light placeholder:text-white/50 text-base w-full bg-transparent focus-within:outline-none'
          type="text"
          placeholder="Digite o nome do mangá"
          value={query}
          onChange={handleInputChange} // Chama a função de pesquisa automática
          required
        />
      </form>

      {loading && <p>Carregando...</p>}
      {error && <p>{error}</p>}

      <ul className='ul-search'>
        {results.map((manga) => (
          <Link to={`/manga/${encodeURIComponent(manga._id)}`} className='a-search' key={manga._id}>
            <li className='li-search'>
              <div className='cover-search'>
                <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='manga-cover-search' />
              </div>
              <div className='info-search'>
                <h3 className='title-search'>{manga.title}</h3>
                <p className='sinopse-search'>{manga.synopsis}</p>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default MangaSearch;
