// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Footer.css */

.footer {
    color: #e0e0e0;
    padding: 1rem;
    text-align: center;
    position: absolute;
    bottom: -100%;
    width: 70vh;
    z-index: 10;
}
  
  .footer p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }
  
  .footer-nav {
    margin-top: 0.5rem;
  }
  
  .footer-nav a {
    color: #80deea;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .footer-nav a:hover {
    color: #ffffff;
  }
  `, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,WAAW;AACf;;EAEE;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,2BAA2B;EAC7B;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* src/components/Footer.css */\r\n\r\n.footer {\r\n    color: #e0e0e0;\r\n    padding: 1rem;\r\n    text-align: center;\r\n    position: absolute;\r\n    bottom: -100%;\r\n    width: 70vh;\r\n    z-index: 10;\r\n}\r\n  \r\n  .footer p {\r\n    margin: 0.5rem 0;\r\n    font-size: 0.9rem;\r\n  }\r\n  \r\n  .footer-nav {\r\n    margin-top: 0.5rem;\r\n  }\r\n  \r\n  .footer-nav a {\r\n    color: #80deea;\r\n    text-decoration: none;\r\n    margin: 0 1rem;\r\n    font-size: 0.9rem;\r\n    transition: color 0.3s ease;\r\n  }\r\n  \r\n  .footer-nav a:hover {\r\n    color: #ffffff;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
