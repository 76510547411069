import ColorThief from 'color-thief-browser';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import io from 'socket.io-client'; // Importar o cliente Socket.IO
import './Livro.css';
const socket = io('http://localhost:3001');

function Livro() {
  const { book } = useParams();
  const [manga, setManga] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [popularMangas, setPopularMangas] = useState([]);
  const [error, setError] = useState(null);
  const [reordering, setReordering] = useState(false); // Para controlar o estado de reordenação
  const [reorderedChapters, setReorderedChapters] = useState([]); // Para controlar os capítulos reordenados
  const [bgColor, setBgColor] = useState('#06060C'); // Estado para a cor de fundo

  useEffect(() => {
    const updateViews = async () => {
      try {
        const response = await fetch(`https://api.arthurcomics.com/api/mangas/${book}/views`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          throw new Error('Erro ao atualizar visualizações');
        }
    
        const popularResponse = await fetch('https://api.arthurcomics.com/api/mangas/popular');
        if (!popularResponse.ok) {
          throw new Error('Erro ao buscar mangás populares');
        }
    
        const popularData = await popularResponse.json();
        setPopularMangas(popularData);
      } catch (error) {
        console.error('Erro ao atualizar visualizações:', error);
        setError(error.message);
      }
    };    
  
    updateViews();

    const fetchManga = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/mangas');
        const data = await response.json();
        
        // Aqui, o 'book' deve ser o ID do manga
        const currentManga = data.find(m => m._id === book);
        setManga(currentManga);
        if (currentManga) {
          extractDominantColor(currentManga.coverImage);
        }
      } catch (error) {
        console.error('Erro ao buscar mangá:', error);
        setError(error.message);
      }
    };
    
    fetchManga();
    
    const fetchChapters = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/chapters');
        const data = await response.json();
        const mangaChapters = data.filter(chapter => chapter.manga.toLowerCase() === book.toLowerCase())
          .sort((a, b) => b.chapterNumber - a.chapterNumber); // Ordena do maior para o menor
        setChapters(mangaChapters);
        setReorderedChapters(mangaChapters); // Inicializa os capítulos reordenados
      } catch (error) {
        console.error('Erro ao buscar capítulos:', error);
        setError(error.message);
      }
    };

    fetchChapters();

    socket.on('mangaUpdated', (updatedManga) => {
      if (updatedManga.title.toLowerCase() === book.toLowerCase()) {
        setManga(updatedManga);
        extractDominantColor(updatedManga.coverImage);
      }
    });

    socket.on('chapterAdded', (newChapter) => {
      if (newChapter.manga.toLowerCase() === book.toLowerCase()) {
        setChapters(prevChapters => [
          ...prevChapters,
          newChapter
        ].sort((a, b) => b.chapterNumber - a.chapterNumber));
      }
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
    };
  }, [book]);

  const extractDominantColor = (imageUrl) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Para evitar problemas de CORS
    img.src = `https://api.arthurcomics.com/${imageUrl.replace(/\\/g, '/')}`;
    img.onload = () => {
      const colorThief = new ColorThief();
      const dominantColor = colorThief.getColor(img);
      setBgColor(`rgb(${dominantColor.join(',')})`);
    };
  };
  const calculateTimeAgo = (postedAtDate, now) => {
    const secondsAgo = Math.floor((now - postedAtDate) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    
    if (secondsAgo < 60) return `${secondsAgo} segundos atrás`;
    if (minutesAgo < 60) return `${minutesAgo} minutos atrás`;
    if (hoursAgo < 24) return `${hoursAgo} horas atrás`;
    return `${daysAgo} dias atrás`;
  };
  if (error) {
    return <div>Erro: {error}</div>;
  }

  if (!manga) {
    return <div>Carregando...</div>;
  }

  const bannerImageUrl = `https://api.arthurcomics.com/${manga.bannerImage.replace(/\\/g, '/')}`;
  const coverImageUrl = `https://api.arthurcomics.com/${manga.coverImage.replace(/\\/g, '/')}`;

  // Função para reordenar capítulos manualmente
  const handleReorderChapters = (index, direction) => {
    const newOrder = [...reorderedChapters];
    const swapIndex = direction === 'up' ? index - 1 : index + 1;
    [newOrder[index], newOrder[swapIndex]] = [newOrder[swapIndex], newOrder[index]]; // Swap chapters
    setReorderedChapters(newOrder);
  };

  return (
    <div className="manga-container grid sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto sm:p-4 p-[4vw]" >
      <div className='w-[calc(100%+2.5rem)] sm:opacity-50 absolute top-0 left-0 blur-[100rem] -ml-10 sm:aspect-[2/1] aspect-[0.5/1] bg-cover bg-center' style={{ backgroundColor: bgColor }}></div>
      <div className='flex w-full sm:gap-10 gap-[4vw] relative'>
        <div className='grid sm:gap-10 gap-[4vw] w-full h-fit'>
          <div className='flex lg:flex-row flex-col sm:gap-10 gap-[4vw]'>
            <div className='grid sm:gap-10 gap-[4vw]'>
              <div className="manga-imagem lg:block flex">
                <div className="grid border border-white/10 overflow-hidden rounded-xl sm:w-64 w-1/2">
                  <div className="manga-cover bg-[image:--photoURL] aspect-[0.75/1] bg-cover bg-center bg-white/10" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${coverImageUrl})` }} />
                </div>
              </div>
            </div>
            <div className="manga-details grid sm:gap-6 gap-[4vw] h-fit">
              <div className='grid sm:gap-4 gap-[4vw]'>
                <h1 className='sm:text-6xl sm:leading-[3.75rem] text-[length:10vw] leading-[11vw] font-bold'>{manga.title}</h1>
              </div>
              <div className='flex flex-wrap sm:gap-2 gap-[2vw]'>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.altTitle}</div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:eye-fill.svg?color=white" className="w-5 -ml-1" /> {manga.views || 0}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ph:user-fill.svg?color=white" className="w-5 -ml-1" /> {manga.author.join(', ')}
                </div>
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>
                  <img src="https://api.iconify.design/ic:baseline-draw.svg?color=white" className="w-5 -ml-1" /> {manga.artist.join(', ')}
                </div>
                {manga.genres.map((genre, index) => (
                  <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg' key={index}>
                    <Link to={`/mangas/genero/${genre}`}>
                      <span className="genre-item">{genre}</span>
                    </Link>
                  </div>
                ))}
                <div className='leading-none h-8 px-3 flex justify-center items-center sm:gap-1.5 gap-[1.5vw] bg-white/10 hover:bg-white/20 transition-all cursor-pointer rounded-lg'>{manga.type}</div>
              </div>
              <div id="expand_content" className="overflow-hidden">
                <p className="p-4 bg-white/10 rounded-lg">{manga.synopsis}</p>
              </div>
            </div>
          </div>

          <div className="manga-chapters">
            <h2 className='sm:text-2xl text-[5.5vw] font-bold'>Capítulos</h2>
            <button onClick={() => setReordering(!reordering)}>
              {reordering ? "Salvar Ordem" : "Reordenar Capítulos"}
            </button>
            <div className="grid relative overflow-hidden">
              <div id="chapters_panel" className="grid">
                <div id="chapters" className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-[3vw]'>
                {reorderedChapters.map((chapter, index) => {
  const postedAtDate = new Date(chapter.postedAt);
  const now = new Date();
  
  // Função para calcular o tempo decorrido
  const timeAgo = calculateTimeAgo(postedAtDate, now);

  return (
    <div key={chapter._id} className="chapter-thumbnail">
      <Link to={`/manga/${book}/capitulo/${chapter.chapterNumber}`} className='group cursor-pointer relative overflow-hidden flex items-center justify-between sm:gap-4 gap-[4vw] hover:bg-white/20 bg-white/10 rounded-2xl sm:p-2 p-[2vw] transition-all'>
        <div className='flex items-center sm:gap-4 gap-[4vw]'>
          <div className="border inline-table border-white/10 bg-white/10 overflow-hidden rounded-xl">
            {chapter.thumbnail && (
              <div
                style={{ 
                  backgroundImage: `url(https://api.arthurcomics.com/${chapter.thumbnail.replace(/\\/g, '/')})`,
                }}
                className="chapter-thumbnail-image aspect-[2/1.5] w-28 bg-white/10 bg-cover bg-center rounded-lg"
              ></div>
            )}
          </div>
          <div className="grid h-fit">
            <span className="flex gap-1 justify-start items-center overflow-hidden">
              <span className="text-sm truncate">Capítulo {chapter.chapterNumber}</span>
            </span>
            <div className="flex items-start flex-col justify-start gap-1.5">
              <div className="text-xs text-white/50 w-fit">
                <time dateTime={postedAtDate.toISOString()} className="text-xs font-header capitalize py-0.5">
                  {timeAgo}
                </time>
              </div>
            </div>
          </div>
        </div>
        <div className="aspect-square h-11 flex justify-center items-center rounded-full group-hover:bg-white/20">
          <img src="https://api.iconify.design/material-symbols:arrow-forward-ios-rounded.svg?color=white" alt="Ícone de avançar" />
        </div>
      </Link>
      {reordering && (
        <div className="chapter-reorder-controls">
          <button onClick={() => handleReorderChapters(index, 'up')} disabled={index === 0}>
            ↑
          </button>
          <button onClick={() => handleReorderChapters(index, 'down')} disabled={index === reorderedChapters.length - 1}>
            ↓
          </button>
        </div>
      )}
    </div>
  );
})}


                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='barralateral-manga max-w-[25rem] w-full 2xl:flex flex-col hidden gap-4 h-fit'>
        <div className='grid gap-4 h-fit'>
          {popularMangas.length > 0 ? (
            popularMangas.map((manga, index) => (
              <Link to={`/manga/${encodeURIComponent(manga._id)}`}>
              <div key={manga._id} className={index === 0 ? 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden' : 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden'}>
                
                  {index === 0 ? (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center '/>
                  ) : (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center'/>
                  )}
                
                <div className={index === 0 ? 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full bg-white/10 p-4 rounded-xl' : 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full'}>
                  <div className={index === 0 ? 'text-3xl font-bold' : 'text-3xl font-bold'}>{index === 0 ? '#1' : index + 1}</div>
                  <div className='grid h-fit gap-1'>
                    <h3 className='text-base truncate leading-5 break-words'>{manga.title}</h3>
                    <div className='flex flex-wrap gap-1.5 mt-0.5'><span className='bg-white/10 border border-white/5 backdrop-blur-3xl w-fit h-fit px-1 rounded-md text-xs capitalize'>{manga.type}</span></div>
                  </div>
                </div>
              </div></Link>
            ))
          ) : (
            <p>Carregando mangás populares...</p>
          )}
        </div>
      </div>
      </div>
    </div>
  );
}

export default Livro;
