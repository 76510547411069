import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import Capitulo from './Capitulo';
import Footer from './Footer';
import GenresPage from './GenresPage';
import Header from './Header';
import Home from './Home';
import Manga from './Manga';
import MangaSearch from './MangaSearch'; // Importando a nova página de pesquisa
import MangasByGenrePage from './MangasByGenrePage';
import Slide from './Slide';

const App = () => {
  useEffect(() => {
    const fetchSiteInfo = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/site-info');
        if (!response.ok) throw new Error('Erro ao buscar informações do site');
        const siteInfo = await response.json();

        // Atualiza as metatags do documento
        document.title = siteInfo.title;
        document.querySelector('meta[name="description"]').setAttribute('content', siteInfo.description);
        document.querySelector("link[rel='icon']").setAttribute('href', siteInfo.icon);
        document.querySelector("link[rel='apple-touch-icon']").setAttribute('href', siteInfo.logo);
      } catch (error) {
        console.error('Erro ao buscar informações do site:', error);
      }
    };

    fetchSiteInfo();
  }, []);
  return (
    <Router>
      <div className="app">
        <div className="hero__object circle"></div>
        <ConditionalHeader />
        <ConditionalSlide />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/manga/:book" element={<Manga />} />
          <Route path="/manga/:book/capitulo/:chapter" element={<Capitulo />} />
          <Route path="/projetos" element={<GenresPage />} />
          <Route path="/mangas/genero/:genre" element={<MangasByGenrePage />} />
          <Route path="/procurar" element={<MangaSearch />} /> {/* Adicionando a rota de pesquisa */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

// Componente para exibir o Slide apenas na página inicial
function ConditionalSlide() {
  const location = useLocation();
  return location.pathname === '/' ? <Slide /> : null;
}

// Componente para exibir o Header condicionalmente
function ConditionalHeader() {
  const location = useLocation();
  const isChapterPage = location.pathname.includes('/capitulo/');
  return !isChapterPage ? <Header /> : null;
}

export default App;
