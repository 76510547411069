import { differenceInDays, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import './Home.css';

const socket = io('http://localhost:3001');

function Home() {
  const [mangas, setMangas] = useState([]);
  const [chapters, setChapters] = useState({});
  const [popularMangas, setPopularMangas] = useState([]);
  const [loadingError, setLoadingError] = useState(null);
  const [ads, setAds] = useState([]); // Inicializa como um array vazio
  const [limit, setLimit] = useState(window.innerWidth <= 640 ? 16 : 15); // Limite inicial

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mangasResponse, chaptersResponse, popularResponse, adsResponse] = await Promise.all([
          fetch('https://api.arthurcomics.com/api/mangas'),
          fetch('https://api.arthurcomics.com/api/chapters'),
          fetch('https://api.arthurcomics.com/api/mangas/popular'),
          fetch('https://api.arthurcomics.com/api/ads')
        ]);

        if (!mangasResponse.ok || !chaptersResponse.ok || !popularResponse.ok || !adsResponse.ok) {
          throw new Error('Erro ao buscar dados');
        }

        const mangasData = await mangasResponse.json();
        const chaptersData = await chaptersResponse.json();
        const popularData = await popularResponse.json();
        const adsData = await adsResponse.json();

        // Organizar capítulos por mangá
        const chaptersByManga = chaptersData.reduce((acc, chapter) => {
          if (!acc[chapter.manga]) acc[chapter.manga] = [];
          acc[chapter.manga].push(chapter);
          return acc;
        }, {});

        // Ordenar capítulos por data de postagem e pegar os dois últimos
        Object.keys(chaptersByManga).forEach(manga => {
          chaptersByManga[manga] = chaptersByManga[manga]
            .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt))
            .slice(0, 2);
        });

        // Ordenar mangás pela data do capítulo mais recente
        const sortedMangas = mangasData.sort((a, b) => {
          const lastChapterA = chaptersByManga[a._id]?.[0];
          const lastChapterB = chaptersByManga[b._id]?.[0];
          if (!lastChapterA) return 1; // Colocar no final se não houver capítulos
          if (!lastChapterB) return -1; // Colocar no final se não houver capítulos
          return new Date(lastChapterB.postedAt) - new Date(lastChapterA.postedAt);
        });

        // Limitar a lista de mangas com base no estado de `limit`
        setMangas(sortedMangas.slice(0, limit));
        setChapters(chaptersByManga);
        setPopularMangas(popularData);
        setAds(adsData);

      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setLoadingError(`Erro ao buscar dados: ${error.message}`);
      }
    };

    fetchData();

    // Escuta eventos de atualização em tempo real
    socket.on('mangaUpdated', (updatedManga) => {
      setMangas(prevMangas => {
        const index = prevMangas.findIndex(manga => manga._id === updatedManga._id);
        if (index !== -1) {
          return [
            ...prevMangas.slice(0, index),
            updatedManga,
            ...prevMangas.slice(index + 1)
          ].slice(0, limit);
        }
        return [...prevMangas.slice(0, limit - 1), updatedManga]; // Adicionar no final e limitar a `limit`
      });
    });

    socket.on('chapterAdded', (newChapter) => {
      setChapters(prevChapters => {
        const updatedChapters = { ...prevChapters };
        if (!updatedChapters[newChapter.manga]) {
          updatedChapters[newChapter.manga] = [];
        }
        updatedChapters[newChapter.manga] = [
          ...updatedChapters[newChapter.manga],
          newChapter
        ].sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt)).slice(0, 2);
        return updatedChapters;
      });
    });

    socket.on('mangaDeleted', (deletedId) => {
      setMangas(prevMangas => prevMangas.filter(manga => manga._id !== deletedId).slice(0, limit));
    });

    return () => {
      socket.off('mangaUpdated');
      socket.off('chapterAdded');
      socket.off('mangaDeleted');
    };
  }, [limit]); // Adicionado limit como dependência

  // Monitorar mudanças no tamanho da janela
  useEffect(() => {
    const handleResize = () => {
      setLimit(window.innerWidth <= 640 ? 16 : 15);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className='home-container'>
     {/* Seção de anúncios */}
<div className="ads-section">
  {ads.filter(ad => ad.section === "home").length > 0 ? (
    ads.filter(ad => ad.section === "home").map(ad => (
      <div key={ad._id} className="ad" dangerouslySetInnerHTML={{ __html: ad.adContent }} /> // Exibe o anúncio
    ))
  ) : (
    <p>Nenhum anúncio disponível para a Home</p>
  )}
</div>


      <div className='flex  sm:gap-10 gap-[4vw] w-full 2xl:max-w-[100rem] max-w-6xl mx-auto  sm:p-4 p-[4vw] relative'>
      <div className='container-1 grid sm:gap-10 gap-[4vw] w-full h-fit'>
        <div className='title-home'>
          <h1>Atualizados Recentemente</h1>
        </div>
        {loadingError && <p>{loadingError}</p>}
        <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 sm:gap-x-4 sm:gap-y-8 gap-4'>
          {mangas.length > 0 ? (
            mangas.map((manga) => (
              <div key={manga._id} className='manga-item '>
                <div className='group text-left relative h-fit w-full overflow-hidden grid transition-all sm:hover:bg-white/10 sm:hover:ring-white/10 sm:hover:ring-[0.5rem] sm:hover:scale-95 sm:hover:rounded-sm'>
                <Link to={`/manga/${encodeURIComponent(manga._id)}`} className='cover-image-link'>
                  <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='manga-cover'/>
                </Link></div>
                <Link to={`/manga/${encodeURIComponent(manga._id)}`} className='title-link absolute inset-x-0 bottom-0 h-1/2'>
                        <div class="gradient-overlay h-full" bis_skin_checked="1"></div>
                        </Link>
                <div className='absoluteinfo absolute bottom-0 pt-2 pb-1 px-1 w-full'>
                <Link to={`/manga/${encodeURIComponent(manga._id)}`} className='title-link'>
                  <h2>{manga.title}</h2>
                </Link>
                
                <div className='chapters flex gap-1 text-xs md:text-sm text-center text-white' >
  {(chapters[manga._id] || []).map((chapter) => {
    const postedAtDate = new Date(chapter.postedAt);
    const daysAgo = differenceInDays(new Date(), postedAtDate);
    const timeAgo = formatDistanceStrict(postedAtDate, new Date(), { addSuffix: false, locale: ptBR });

    return (
      <Link
      to={`/manga/${encodeURIComponent(manga._id)}/capitulo/${chapter.chapterNumber}`}
      key={chapter._id}
      className='font-header flex-center w-full py-1'
    >
      <div className='w-full z-20 bg-gray-900/50 backdrop-blur min-h-8 rounded-md'>
        <div className="font-header flex-center w-full py-1">Capítulo {chapter.chapterNumber}</div>

        {/* Verifica se foi postado há 3 dias ou menos */}
        {daysAgo <= 3 && (
          <div className="!bg-rose-400/10 !text-rose-600 bg-gray-900/30 flex justify-center items-center rounded-b-md text-gray-400">
            <i className="heroicons--fire-20-solid mr-1"></i>
            <time dateTime={postedAtDate.toISOString()} className="text-xs font-header capitalize py-0.5">
              {timeAgo} 
            </time>
          </div>
        )}

        {/* Se foi postado há mais de 3 dias */}
        {daysAgo > 3 && (
          <div className="bg-gray-900/30 text-gray-400 rounded-b-md flex justify-center items-center">
            <time dateTime={postedAtDate.toISOString()} className="text-xs font-header capitalize py-0.5">
              {timeAgo}
            </time>
          </div>
        )}
      </div>
    </Link>
    ); 
  })}
</div>
                </div>
                </div>
            ))
          ) : (
            <p>Carregando mangás...</p>
          )}
        </div>
        {/* Botão Ver Mais */}
        <div className='flex justify-center mt-6'>
            <Link to='/projetos' className='btn-ver-mais'>Ver Mais</Link>
          </div>
        </div>
      <div className='barralateral max-w-[25rem] w-full 2xl:flex flex-col hidden gap-4 h-fit'>
        {/* Seção de anúncios */}
        <div className="ads-section">
          {Array.isArray(ads) && ads.filter(ad => ad.section === "sidebarAds").length > 0 ? (
            ads.filter(ad => ad.section === "sidebarAds").map(ad => (
              <div key={ad._id} className="ad" dangerouslySetInnerHTML={{ __html: ad.adContent }} /> // Exibe o anúncio
            ))
          ) : (
            <p>Nenhum anúncio disponível para a Home</p>
          )}
        </div>
      <div className='title-home flex  justify-between items-center h-fit sm:mb-6'>
      <h1 className='sm:text-2xl text-[5.5vw] font-bold'>Populares</h1>
        </div>
        
        <div className='grid gap-4 h-fit'>
          {popularMangas.length > 0 ? (
            popularMangas.map((manga, index) => (
              <div key={manga._id} className={index === 0 ? 'group bg-white/10 hover:bg-white/20 rounded-xl transition-all border border-white/5 grid overflow-hidden' : 'group flex gap-3.5 sm:p-3.5 p-[2.5vw] items-center bg-white/10 hover:bg-white/20 rounded-xl transition-all aspect-[2/0.5] border border-white/5 overflow-hidden'}>
                <Link to={`/manga/${encodeURIComponent(manga._id)}`}>
                  {index === 0 ? (
                    <img src={`https://api.arthurcomics.com/${manga.bannerImage}`} alt={manga.title} className='popular-manga-banner grid transition-all aspect-[2/1] w-full bg-white/10 bg-no-repeat bg-cover bg-center '/>
                  ) : (
                    <img src={`https://api.arthurcomics.com/${manga.coverImage}`} alt={manga.title} className='popular-manga-cover grid transition-all aspect-[0.75/1] border border-white/10 h-full rounded-lg bg-white/10 bg-no-repeat bg-cover bg-center'/>
                  )}
                </Link>
                <div className={index === 0 ? 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full bg-white/10 p-4 rounded-xl' : 'flex  gap-3.5 items-center justify-start sm:py-2.5 sm:px-3.5 py-[2vw] px-[2.5vw] w-full'}>
                  <div className={index === 0 ? 'text-3xl font-bold' : 'text-3xl font-bold'}>{index === 0 ? '#1' : index + 1}</div>
                  <div className='grid h-fit gap-1'>
                    <h3 className='text-base truncate leading-5 break-words'>{manga.title}</h3>
                    <div className='flex flex-wrap gap-1.5 mt-0.5'><span className='bg-white/10 border border-white/5 backdrop-blur-3xl w-fit h-fit px-1 rounded-md text-xs capitalize'>{manga.type}</span></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Carregando mangás populares...</p>
          )}
        </div>
      </div>
    </div></div>
  );
}

export default Home;