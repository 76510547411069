// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.manga-cover-search {
    max-width: 80px;
}
li.li-search {
    display: flex;
    align-items: center;
    gap: 20px;
}
p.sinopse-search {
    max-width: 200px;
    text-align: left;
    overflow: hidden;
    font-size: 13px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/MangaSearch.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,uBAAuB;IACvB,oBAAoB;IACpB,qBAAqB;IACrB,aAAa;IACb,4BAA4B;AAChC","sourcesContent":["img.manga-cover-search {\n    max-width: 80px;\n}\nli.li-search {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n}\np.sinopse-search {\n    max-width: 200px;\n    text-align: left;\n    overflow: hidden;\n    font-size: 13px;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    line-clamp: 2;\n    -webkit-box-orient: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
